// Global
import './styles.scss';
import React from 'react';

// Components
import { Anchor } from 'components/Anchor';
import { AriaButton } from 'components/AriaButton';
import { Button } from 'components/Button';
import { Logo } from 'components/Logo';
import { ModalFileUploadEnrollment } from 'modals/ModalFileUploadEnrollment';
import { Paragraph } from 'components/Paragraph';

// Services
import { doLogout } from 'services/auth/async';
import { getIsRefreshTokenValid } from 'services/auth/selectors';
import { getOrganizationName } from 'services/organizations/selectors';

// Misc
import clsx from 'clsx';
import { EbDispatch } from 'store';
import { matchRoutePath } from 'utils/matchRoutePath';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const blackListUploadFilePathMath = [
  '/organizations',
  '/sign-in',
  '/user-management',
  '/files',
  '/api'
];

interface HeaderProps {
  nonAuth?: boolean;
}

const Header: React.FC<HeaderProps> = ({ nonAuth }) => {
  // Hooks
  const dispatch: EbDispatch = useDispatch();
  const location = useLocation();

  const isPathMatching = matchRoutePath(blackListUploadFilePathMath, location.pathname);

  // Selectors
  const isSessionValid = useSelector(getIsRefreshTokenValid);
  const organizationName = useSelector(getOrganizationName);

  // State
  const [isUploadFileModalVisible, setIsUploadFileModalVisible] = React.useState(false);

  // Vars
  const displayAuthContent = isSessionValid && !nonAuth;
  const displayOrgContent = displayAuthContent && !isPathMatching;
  const displayCompanyName = displayOrgContent ? organizationName : 'PORTAL';

  const classes = clsx('eb-header', {
    'eb-header-logged-in': displayAuthContent,
    'eb-header-logged-out': !displayAuthContent
  });

  const uploadButton = displayOrgContent ? (
    <Button
      className="eb-header-nav-upload-button"
      icon="document_add"
      intent="secondary"
      onClick={() => setIsUploadFileModalVisible(true)}
    >
      Upload file
    </Button>
  ) : null;

  const headerRightContent = () => {
    switch (displayAuthContent) {
      case true:
        return (
          <>
            {uploadButton}

            {/*
             TODO: Uncomment when ready
             <Button icon="support" intent="text" type="icon" />
             <Button icon="bell" intent="text" type="icon" />

             <HeaderAccountMenu />
             */}
            <AriaButton onClick={() => dispatch(doLogout())} type="primary">
              Sign out
            </AriaButton>
          </>
        );
      default:
        return (
          <Paragraph className="eb-header-nav-support">
            {' '}
            Get help —{' '}
            <Anchor
              className="eb-header-nav-support-email"
              href={'mailto:support@aura.com'}
              intent="secondary"
            >
              support@aura.com
            </Anchor>
          </Paragraph>
        );
    }
  };

  return (
    <>
      <header className={classes}>
        <div className="eb-header-content">
          <Logo displayCompanyName={displayCompanyName} isSessionValid={displayAuthContent} />
          <div className="eb-header-nav">{headerRightContent()}</div>
        </div>
      </header>

      <ModalFileUploadEnrollment
        hide={() => setIsUploadFileModalVisible(false)}
        isVisible={isUploadFileModalVisible}
      />
    </>
  );
};
export { Header };
