import './styles.scss';
import React from 'react';

// Components
import { DropdownItem } from 'components/DropdownItem';
import { DropdownIcon } from 'components/DropdownIcon';
import { Filter } from 'components/Filter';
import { Heading } from 'components/Heading';
import { LayoutPage } from 'components/LayoutPage';
import { Loading } from 'components/Loading';
import { ModalConfirmation } from 'modals/ModalConfirmation';
import { FileUploadType, NoFiles } from 'components/NoFiles';
import { Paragraph } from 'components/Paragraph';
import { Search } from 'components/Search';
import { Table } from 'components/Table';
import { Toast } from 'components/Toast';

// Services
import { doBulkActionMembers, doGetCurrentCompanyPeople } from 'services/people/async';
import {
  getCurrentCompanyPeople,
  getPeopleParamsFilters,
  getPeopleParamsPage,
  getPeopleParamsPageSize,
  getPeopleParamsSearch,
  getPeopleParamsTotal,
  getPeopleParamsTotalPages,
  getPeopleParamsTotalWithoutFilters
} from 'services/people/selectors';
import { getEndpointsAnyPending } from 'services/api/selectors';
import {
  getOrganizationIsSupressPortabilityEmails,
  getOrganizationIsSupressTermEmails,
  getOrganizationsSelected
} from 'services/organizations/selectors';
import { resetFilters, setFilter, setPage, setPageSize, setSearch } from 'services/people/reducers';
import { setToastError, setToastSuccess } from 'services/toast/reducers';

// Misc
import { BulkActionStatus, MemberAction, SubscriptionStatus } from 'services/people/types';
import { EbDispatch, EbState } from 'store';
import { FILTER_OPTIONS, PEOPLE_COLUMNS } from './data';
import { ModalConfirmationProps } from 'modals/ModalConfirmation/types';
import { useDispatch, useSelector } from 'react-redux';
import { searchSchema } from './searchSchema';
import { useEffectDidUpdate } from 'hooks/useEffectDidUpdate';
import { useFilter } from 'hooks/useFilterToggle';
import { useSearchButton } from 'hooks/useSearchButton';

// Const
const searchSuggestions = (
  <>
    <Paragraph>Suggestions:</Paragraph>
    <Paragraph>Make sure that name is spelled correctly.</Paragraph>
    <Paragraph>Try different name.</Paragraph>
  </>
);

function People() {
  // Hooks
  const dispatch: EbDispatch = useDispatch();

  // Hooks - selectors
  const companyCode = useSelector(getOrganizationsSelected);
  const supressTermEmails = useSelector(getOrganizationIsSupressTermEmails);
  const suppressPortabilityEmails = useSelector(getOrganizationIsSupressPortabilityEmails);
  const people = useSelector(getCurrentCompanyPeople);
  const totalPeople = useSelector(getPeopleParamsTotalWithoutFilters);
  const totalPeopleSearch = useSelector(getPeopleParamsTotal);
  const page = useSelector(getPeopleParamsPage);
  const pageSize = useSelector(getPeopleParamsPageSize);
  const totalPages = useSelector(getPeopleParamsTotalPages);
  const search = useSelector(getPeopleParamsSearch);
  const isPending = useSelector((state: EbState) =>
    getEndpointsAnyPending(state, [doGetCurrentCompanyPeople, doBulkActionMembers])
  );

  // Hooks - selectors
  const filters = useSelector(getPeopleParamsFilters);

  // Hooks - state
  const [isConfirmTermModalVisible, setIsConfirmTermModalVisible] = React.useState(false);
  const [selectedUserIds, setSelectedUserIds] = React.useState<string[]>([]);
  const [confirmationModalProps, setConfirmationModalProps] = React.useState<
    Partial<ModalConfirmationProps>
  >({});

  // Hooks - custom
  const { searchButton, isSearchInputActive } = useSearchButton();
  const { filterButton, isFilterOpen, toggleFilter } = useFilter(Object.keys(filters).length);

  // Handlers
  const getPeople = React.useCallback(() => {
    if (companyCode) void dispatch(doGetCurrentCompanyPeople());
  }, [companyCode, dispatch]);

  const updatePageSize = React.useCallback(
    (pageSize: number) => {
      dispatch(setPageSize(pageSize));
      getPeople();
    },
    [dispatch, getPeople]
  );

  const changePage = React.useCallback(
    (page: number) => {
      dispatch(setPage(page));
      getPeople();
    },
    [dispatch, getPeople]
  );

  const onSearch = React.useCallback(
    (searchText: string) => {
      dispatch(setSearch(searchText));
      getPeople();
    },
    [dispatch, getPeople]
  );

  const onSearchCancel = React.useCallback(() => {
    dispatch(resetFilters());
    getPeople();
  }, [dispatch, getPeople]);

  const termSelectedUsers = React.useCallback(
    async (action: MemberAction, noNotification = false) => {
      try {
        setIsConfirmTermModalVisible(false);
        setSelectedUserIds([]);
        await dispatch(doBulkActionMembers(action, selectedUserIds, noNotification));
        dispatch(setToastSuccess('Selected members are being updated.'));
      } catch (_) {
        dispatch(setToastError('Error updating users. Please try again.'));
      }
    },
    [selectedUserIds, dispatch]
  );

  const handleActionSelectedUsers = React.useCallback(
    async (action: MemberAction, noNotification = false) => {
      const mainText = `You are about to ${action} ${selectedUserIds.length} ${
        selectedUserIds.length > 1 ? 'users' : 'user'
      }. Their subscriptions will be closed`;
      setConfirmationModalProps({
        primaryButtonText: 'Confirm',
        text: noNotification
          ? `${mainText} and they will not receive any notification.`
          : `${mainText}.`,
        onPrimaryButtonClick: () => termSelectedUsers(action, noNotification)
      });
      setIsConfirmTermModalVisible(true);
    },
    [selectedUserIds.length, termSelectedUsers]
  );

  // Hooks - effects
  React.useEffect(() => {
    getPeople();
  }, [getPeople]);

  React.useEffect(
    () => () => {
      dispatch(resetFilters());
    },
    [dispatch]
  );

  // Hooks - useEffect
  useEffectDidUpdate(() => {
    if (!isSearchInputActive) {
      dispatch(setSearch(''));
      getPeople();
    }
  }, [isSearchInputActive, dispatch]);

  const handleFilter = (filters: Record<string, any>) => {
    void dispatch(setFilter(filters));
    getPeople();
    toggleFilter();
  };

  // Render
  const renderHeading = () => (
    <>
      <div className="eb-people-heading">
        <Heading size={32} type="h1">
          People
        </Heading>
        <div className="eb-people-heading-filter-buttons">
          {totalPeople > 0 && searchButton}
          {filterButton}
        </div>
      </div>
      {totalPeople > 0 && (
        <Paragraph className="eb-people-total">Total {totalPeople} records</Paragraph>
      )}
    </>
  );

  const renderTable = () => {
    if (isPending) return <Loading isCentered>Loading...</Loading>;
    if (totalPeople === 0)
      return <NoFiles fileUploadType={FileUploadType.ENROLLMENT} heading="No people yet" />;

    const noUsersSelected = selectedUserIds.length < 1;

    if (!search || totalPeopleSearch > 0) {
      return (
        <>
          <div className="eb-people-table-header">
            <Heading size={22} type="h5">
              Records
            </Heading>
            <DropdownIcon hoverText="Take action on selected members" icon="more">
              <DropdownItem
                isDisabled={noUsersSelected || supressTermEmails}
                onClick={() => handleActionSelectedUsers(MemberAction.TERM)}
              >
                Term
              </DropdownItem>
              <DropdownItem
                isDisabled={noUsersSelected}
                onClick={() => handleActionSelectedUsers(MemberAction.TERM, true)}
              >
                Term without email
              </DropdownItem>
              <DropdownItem
                isDisabled={noUsersSelected || suppressPortabilityEmails}
                onClick={() => handleActionSelectedUsers(MemberAction.PORT)}
              >
                Port
              </DropdownItem>
              <DropdownItem
                isDisabled={noUsersSelected}
                onClick={() => handleActionSelectedUsers(MemberAction.PORT, true)}
              >
                Port without email
              </DropdownItem>
            </DropdownIcon>
          </div>
          <Table
            columns={PEOPLE_COLUMNS}
            data={people}
            itemsTotal={totalPeopleSearch}
            page={page}
            pageSize={pageSize}
            pagesTotal={totalPages}
            rowKey="userId"
            selectable={{
              selectedRowKeys: selectedUserIds,
              rowSelectDisabled: (row) =>
                row.bulkAction?.state === BulkActionStatus.PROCESSING ||
                row.status?.toLowerCase() === SubscriptionStatus.PORTED,
              onRowSelect: setSelectedUserIds
            }}
            setPage={changePage}
            setPageSize={updatePageSize}
          />
        </>
      );
    }

    return null;
  };

  return (
    <LayoutPage className="eb-people" heading={renderHeading()}>
      <Toast />
      <Search
        customSchema={searchSchema}
        isVisible={isSearchInputActive}
        onCancel={onSearchCancel}
        onSubmit={onSearch}
        placeHolder="Search by Name, UserId or companyUserId"
        request={doGetCurrentCompanyPeople}
        suggestions={searchSuggestions}
        totalResultsFound={totalPeopleSearch}
      />
      <Filter
        isVisible={isFilterOpen}
        onApply={handleFilter}
        onCancel={toggleFilter}
        options={FILTER_OPTIONS}
        request={doGetCurrentCompanyPeople}
      />

      {renderTable()}
      <ModalConfirmation
        isVisible={isConfirmTermModalVisible}
        onPrimaryButtonClick={confirmationModalProps.onPrimaryButtonClick ?? (() => {})}
        onSecondaryButtonClick={() => {
          setIsConfirmTermModalVisible(false);
        }}
        secondaryButtonText="Cancel"
        {...confirmationModalProps}
      />
    </LayoutPage>
  );
}

export { People };
