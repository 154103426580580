// Globals
import classes from './styles.module.scss';
import React from 'react';

// Components
import { ModalFileUpload } from 'modals/ModalFileUpload';
import { DropdownFilter } from 'components/DropdownFilter';

// Services
import { doGetCompanyCuidFiles } from 'services/cuid/async';
import { doUploadExternalIdFile, doUploadProgramChangeFile } from 'services/upload/async';
import { setToastSuccess } from 'services/toast/reducers';

// Misc
import { useFileUploadManager } from 'hooks/useFileUploadManager';
import { useDispatch } from 'react-redux';

// Types
import { EbDispatch } from 'store';
import { ModalFileUploadEnrollmentProps } from './types';
import { FileType } from 'services/files/types';

const FILE_EXTENSIONS = ['.csv'];
const REQUIRED_CSV_HEADERS_BY_TYPE: Record<string, string[]> = {
  [FileType.EXTERNAL_ID_UPDATE]: ['companycode', 'externalid', 'newexternalid', 'userid'],
  [FileType.PROGRAM_CHANGE]: [
    'companycode',
    'userid',
    'externalid',
    'campaigncode',
    'newcompanycode',
    'newcampaigncode',
    'newplancode'
  ]
};

const FILE_TYPE_TITLE: Record<string, string> = {
  [FileType.EXTERNAL_ID_UPDATE]: 'CUID update',
  [FileType.PROGRAM_CHANGE]: 'Program change'
};

const SELECT_OPTIONS = Object.entries(FILE_TYPE_TITLE).map(([typeValue, title]) => ({
  value: typeValue,
  label: title
}));

// Component
const ModalFileUploadBulkAction: React.FC<ModalFileUploadEnrollmentProps> = ({
  hide,
  isVisible,
  onSuccessClose
}) => {
  // Hooks
  const dispatch: EbDispatch = useDispatch();

  // State
  const [selectedType, setSelectedType] = React.useState(FileType.EXTERNAL_ID_UPDATE);

  // Handlers
  const handleUpload = async (file: File) => {
    if (selectedType === FileType.EXTERNAL_ID_UPDATE) {
      await dispatch(doUploadExternalIdFile(file));
    } else if (selectedType === FileType.PROGRAM_CHANGE) {
      await dispatch(doUploadProgramChangeFile(file));
    }
    void dispatch(doGetCompanyCuidFiles());
    hide();
    dispatch(setToastSuccess('File has been successfully uploaded'));
  };

  const renderHeader = () => {
    return (
      <div className={classes.headingContent}>
        Select file type to upload:
        <DropdownFilter
          onChange={(e) => setSelectedType(e?.dropdownFilter.value as FileType)}
          options={SELECT_OPTIONS}
          value={selectedType}
        />
      </div>
    );
  };

  // Hooks - custom
  const { uploadFile } = useFileUploadManager(
    handleUpload,
    FILE_EXTENSIONS,
    REQUIRED_CSV_HEADERS_BY_TYPE[selectedType]
  );

  return (
    <ModalFileUpload
      handleUploadFile={uploadFile}
      hide={hide}
      isVisible={isVisible}
      onSuccessClose={onSuccessClose}
      title={renderHeader()}
    />
  );
};

export { ModalFileUploadBulkAction };
