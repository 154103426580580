import * as endpoints from 'endpoints';
import { ApiDomain } from 'services/api/types';
import { eb } from 'services/api/async';
import { EbThunk } from 'store/types';
import { getOrganizationsSelected } from 'services/organizations/selectors';
import { CuidUpdateRequest, GetCuidFilesResponse, UserData } from './types';
import { setCompanyCuidFiles } from './reducers';
import { getCuidFilesParams } from './selectors';
import { objectToAxiosParams } from 'utils/objectToApiParams';

doGetCompanyCuidFiles.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.CUID_FILES,
  method: 'GET'
};

doUpdateCuid.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.CUID_UPDATE,
  method: 'POST'
};

export function doGetCompanyCuidFiles(): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      data: { endpoint, method }
    } = doGetCompanyCuidFiles;
    const { page, pageSize, search } = getCuidFilesParams(getState());
    const companyCode = getOrganizationsSelected(getState());
    const params = objectToAxiosParams({ page, pageSize, search });

    const files = await dispatch(
      eb<GetCuidFilesResponse>({
        endpoint,
        method,
        params,
        pathParams: [companyCode]
      })
    );
    dispatch(setCompanyCuidFiles(files, companyCode));
  };
}

export function doUpdateCuid(
  { userId, companyUserId }: UserData,
  id: string
): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      data: { endpoint, method }
    } = doUpdateCuid;
    const companyCode = getOrganizationsSelected(getState());
    const data: CuidUpdateRequest = {
      userId,
      oldCompanyUserId: companyUserId,
      newCompanyUserId: id,
      companyCode
    };

    await dispatch(
      eb<GetCuidFilesResponse>({
        endpoint,
        method,
        data
      })
    );
  };
}
