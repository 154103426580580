// Globals
import './styles.scss';
import React from 'react';

// Components
import { AriaButton } from 'components/AriaButton';
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { Input } from 'components/Input';

// Services
import { getEndpointPending } from 'services/api/selectors';

// Misc
import clsx from 'clsx';
import { EbState } from 'store';
import { FilterOption, FilterProps } from './types';
import { useSelector } from 'react-redux';

// Component
const Filter = React.memo(
  ({ onCancel, onApply, request, isVisible = true, options }: FilterProps) => {
    // Hooks - Selectors
    const isRequestPending = useSelector((state: EbState) => getEndpointPending(state, request));

    // Hooks - state
    const [appliedFilters, setAppliedFilter] = React.useState<Record<string, any>>({});

    // Vars
    const appliedFiltersCount = Object.values(appliedFilters).length;

    // Handlers
    const onFilterApply = React.useCallback(() => {
      onApply(appliedFilters);
    }, [appliedFilters, onApply]);

    const onFilterReset = React.useCallback(() => {
      setAppliedFilter({});
      onApply({});
    }, [onApply]);

    const handleCheckboxChange = React.useCallback(
      (key: string, event: React.ChangeEvent) => {
        const { checked, name } = event.target as HTMLInputElement;
        let filters = { ...appliedFilters };
        let values: Set<string> = new Set(appliedFilters[key]) || [];

        if (checked) {
          // Selected "All", no need to apply filter
          if (name === '') values = new Set(['']);
          else {
            values.delete('');
            values.add(name);
          }
        } else {
          values.delete(name);
        }

        if ([...values].length === 0) delete filters[key];
        else filters = { ...filters, [key]: [...values] };

        setAppliedFilter(filters);
      },
      [appliedFilters]
    );

    const handleRadioChange = React.useCallback(
      (key: string, event: React.ChangeEvent) => {
        const { dataset } = event.target as HTMLInputElement;
        setAppliedFilter({ ...appliedFilters, [key]: dataset.value });
      },
      [appliedFilters]
    );

    const handleTagChange = React.useCallback(
      (key: string, value: string) => {
        let filters = { ...appliedFilters };
        let values: Set<string> = new Set(appliedFilters[key]) || [];

        if (values.has(value)) values.delete(value);
        else values.add(value);

        if ([...values].length === 0) delete filters[key];
        else filters = { ...filters, [key]: [...values] };

        setAppliedFilter(filters);
      },
      [appliedFilters]
    );

    const renderFilterValues = (item: FilterOption) => {
      switch (item.type) {
        case 'choice': {
          return (
            <div className="eb-filter-item-inputs">
              {item.isMulti
                ? item.values.map(({ key, label }) => (
                    <Input
                      checked={appliedFilters[item.key]?.includes(key)}
                      key={key}
                      name={key}
                      onChange={(e) => handleCheckboxChange(item.key, e)}
                      type="checkbox"
                    >
                      {label}
                    </Input>
                  ))
                : item.values.map(({ key, label }) => (
                    <Input
                      checked={appliedFilters[item.key] === key}
                      data-value={key}
                      key={key}
                      name={item.key}
                      onChange={(e) => handleRadioChange(item.key, e)}
                      type="radio"
                    >
                      {label}
                    </Input>
                  ))}
            </div>
          );
        }
        case 'tag': {
          return (
            <div className="eb-filter-tags">
              {item.values.map(({ key, label }) => {
                const isSelected = appliedFilters[item.key]?.includes(key);
                const className = clsx('eb-filter-tags-option', {
                  'eb-filter-tags-option-selected': isSelected
                });

                return (
                  <div
                    className={className}
                    key={key}
                    onClick={() => handleTagChange(item.key, key)}
                  >
                    {label}
                  </div>
                );
              })}
            </div>
          );
        }
        default:
          return null;
      }
    };

    // Render
    const classNames = clsx('eb-filter', { 'eb-filter-hidden': !isVisible });

    return (
      <div className={classNames}>
        {options.map((option, index) => (
          <div className="eb-filter-item" key={index}>
            <Heading className="eb-filter-item-heading" size={16} type="h6">
              {option.label}
            </Heading>
            {renderFilterValues(option)}
          </div>
        ))}
        <div className="eb-filter-buttons">
          <AriaButton className="eb-filter-buttons-left" onClick={onFilterReset} type="primary">
            <Icon name="refresh" />
            Reset
          </AriaButton>
          <div className="eb-filter-buttons-right">
            <Button
              display="inline"
              intent="secondary"
              isDisabled={isRequestPending}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button isDisabled={!appliedFiltersCount} onClick={onFilterApply}>
              Apply
              {appliedFiltersCount > 0 &&
                ` ${appliedFiltersCount} filter${appliedFiltersCount > 1 ? 's' : ''}`}
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

export { Filter };
