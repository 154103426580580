import * as endpoints from 'endpoints';
import config from 'config';
import { ApiDomain } from 'services/api/types';
import { amz, eb } from 'services/api/async';
import { EbThunk } from 'store/types';
import { getOrganizationsSelected } from 'services/organizations/selectors';
import { PresignedUrl, EnvType } from './types';
import { FileType } from '../files/types';

doGetPreSignedUrl.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.PRESIGNED_URL_GET,
  method: 'POST'
};

doUploadEnrollmentFile.data = {
  domain: ApiDomain.FTE,
  endpoint: endpoints.FILE_TRANSFORMER_EXTERNAL_PUT,
  method: 'PUT'
};

doUploadExternalIdFile.data = {
  domain: ApiDomain.FTE,
  endpoint: endpoints.FILE_TRANSFORMER_EXTERNAL_ID,
  method: 'PUT'
};

doUploadProgramChangeFile.data = {
  domain: ApiDomain.FTE,
  endpoint: endpoints.FILE_TRANSFORMER_PROGRAM_CHANGE,
  method: 'PUT'
};

export function doGetPreSignedUrl(
  fileName: string,
  fileType: FileType
): EbThunk<Promise<PresignedUrl>> {
  return async (dispatch, getState) => {
    // Request
    const {
      data: { endpoint, method }
    } = doGetPreSignedUrl;

    const companyCode = getOrganizationsSelected(getState());

    return await dispatch(
      eb<PresignedUrl>({
        endpoint,
        method,
        data: { fileName, fileType, companyCode }
      })
    );
  };
}

export function doUploadEnrollmentFile(file: File, env?: EnvType): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    // Request Data
    const {
      data: { endpoint, method }
    } = doUploadEnrollmentFile;

    let fileName = file.name;
    const fileNameLowerCase = fileName.toLowerCase();
    // append test_ to TEST env files
    if (env === EnvType.TEST && !fileNameLowerCase.startsWith('test_')) {
      fileName = `test_${fileName}`;
    }

    const { url } = await dispatch(doGetPreSignedUrl(fileName, FileType.ENROLLMENT));

    const queryParams = url.substring(url.indexOf('?') + 1);
    const params = Object.fromEntries(new URLSearchParams(queryParams).entries());

    const companyCode = getOrganizationsSelected(getState());

    return await dispatch(
      amz({
        baseURL: config.fte_api_origin,
        endpoint: endpoint,
        method,
        data: file,
        params,
        pathParams: [companyCode, fileName]
      })
    );
  };
}

export function doUploadExternalIdFile(file: File): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    // Request Data
    const {
      data: { endpoint, method }
    } = doUploadExternalIdFile;

    let fileName = file.name;

    const { url } = await dispatch(doGetPreSignedUrl(fileName, FileType.EXTERNAL_ID_UPDATE));
    const queryParams = url.substring(url.indexOf('?') + 1);
    const params = Object.fromEntries(new URLSearchParams(queryParams).entries());
    const companyCode = getOrganizationsSelected(getState());

    return await dispatch(
      amz({
        baseURL: config.fte_api_origin,
        endpoint: endpoint,
        method,
        data: file,
        params,
        pathParams: [companyCode, fileName]
      })
    );
  };
}

export function doUploadProgramChangeFile(file: File): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    // Request Data
    const {
      data: { endpoint, method }
    } = doUploadProgramChangeFile;

    let fileName = file.name;

    const { url } = await dispatch(doGetPreSignedUrl(fileName, FileType.PROGRAM_CHANGE));
    const queryParams = url.substring(url.indexOf('?') + 1);
    const params = Object.fromEntries(new URLSearchParams(queryParams).entries());
    const companyCode = getOrganizationsSelected(getState());

    return await dispatch(
      amz({
        baseURL: config.fte_api_origin,
        endpoint: endpoint,
        method,
        data: file,
        params,
        pathParams: [companyCode, fileName]
      })
    );
  };
}
