import * as endpoints from 'endpoints';
import { ApiDomain } from 'services/api/types';
import { eb } from 'services/api/async';
import { EbThunk } from 'store/types';
import { getRefreshTokenUserId } from 'services/auth/selectors';
import { getOrganizationsSelected } from 'services/organizations/selectors';
import {
  FileReviewStatus,
  FilesListRequestParams,
  FileType,
  FileUpdateRequestParams,
  GetFilesListResponse
} from './types';
import { setFiles, updateListFile } from './reducers';
import { getFilesParams } from './selectors';

doListFiles.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.FILES_LIST,
  method: 'POST'
};

doUpdateFileMetadata.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.FILE_UPDATE,
  method: 'POST'
};

export function doListFiles(fileType = [FileType.ENROLLMENT]): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      data: { endpoint, method }
    } = doListFiles;
    const selectedCompanyCode = getOrganizationsSelected(getState());
    const { page, pageSize, statusColor, uploadedAt, companyCode } = getFilesParams(getState());

    const data: FilesListRequestParams = {
      page,
      pageSize,
      statusColor,
      uploadedAt,
      companyCode: companyCode ? companyCode : selectedCompanyCode || undefined,
      type: fileType
    };

    const response = await dispatch(
      eb<GetFilesListResponse>({
        endpoint,
        method,
        data
      })
    );
    dispatch(setFiles(response));
  };
}

export function doUpdateFileMetadata({
  reviewStatus,
  fileId
}: {
  fileId: string;
  reviewStatus?: FileReviewStatus;
}): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      data: { endpoint, method }
    } = doUpdateFileMetadata;

    const userId = getRefreshTokenUserId(getState());

    const data: FileUpdateRequestParams = {
      id: fileId,
      reviewedBy: userId,
      reviewStatus
    };

    const updatedFile = await dispatch(
      eb<FileUpdateRequestParams>({
        endpoint,
        method,
        data
      })
    );
    dispatch(updateListFile(updatedFile));
  };
}
