import {
  AllFilesState,
  FileListItem,
  FilesActionTypes,
  FilesAllAction,
  FilesState,
  FileType,
  GetFilesListResponse
} from './types';
import { initialFilesListParams, initialState } from './selectors';
import { combineReducers } from '@reduxjs/toolkit';

export const setFiles = (data: GetFilesListResponse) => ({
  type: FilesActionTypes.SET_FILES,
  data
});

export const setFilesPage = (data: number) => ({
  type: FilesActionTypes.SET_FILES_PAGE,
  data
});

export const setFilesPageSize = (data: number) => ({
  type: FilesActionTypes.SET_FILES_PAGE_SIZE,
  data
});

export const setFilesFilters = (data: {
  status?: string;
  uploadedAt?: string;
  companyCode?: string;
  type?: FileType[];
}) => ({
  type: FilesActionTypes.SET_FILES_FILTERS,
  data
});

export const resetFilesFilters = () => ({
  type: FilesActionTypes.RESET_FILES_FILTERS
});

export const updateListFile = (data: Partial<FileListItem>) => ({
  data,
  type: FilesActionTypes.UPDATE_LIST_FILE
});

function allFiles(filesState = initialState.allFiles, action: FilesAllAction): AllFilesState {
  switch (action.type) {
    case FilesActionTypes.SET_FILES: {
      const {
        data: { files, total = 0, totalPages, ...restParams }
      } = action;
      const { totalWithoutFilters, companyCode, statusColor, uploadedAt, type } = filesState.params;
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      const areFiltersActive = companyCode || statusColor || uploadedAt || type;

      return {
        list: files,
        params: {
          ...(filesState?.params || initialFilesListParams),
          ...restParams,
          total: total ?? 0,
          totalPages: totalPages ?? 0,
          totalWithoutFilters: areFiltersActive ? totalWithoutFilters : total
        }
      };
    }
    case FilesActionTypes.SET_FILES_PAGE: {
      const page = action.data;
      return {
        ...filesState,
        params: {
          ...(filesState?.params || initialFilesListParams),
          page
        }
      };
    }
    case FilesActionTypes.SET_FILES_PAGE_SIZE: {
      const pageSize = action.data;
      return {
        ...filesState,
        params: {
          ...(filesState?.params || initialFilesListParams),
          page: 0,
          pageSize
        }
      };
    }
    case FilesActionTypes.SET_FILES_FILTERS: {
      const filters = action.data;
      return {
        ...filesState,
        params: {
          ...(filesState?.params || initialFilesListParams),
          ...filters,
          page: 0
        }
      };
    }
    case FilesActionTypes.RESET_FILES_FILTERS: {
      return {
        ...filesState,
        params: initialFilesListParams
      };
    }
    case FilesActionTypes.UPDATE_LIST_FILE: {
      const updatedList = [...filesState.list].map((file) =>
        file.id === action.data.id ? { ...file, ...action.data } : file
      );
      return {
        ...filesState,
        list: updatedList
      };
    }
    default:
      return filesState;
  }
}

export const files = combineReducers<FilesState>({ allFiles });
