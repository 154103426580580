import { AccessLevel } from 'services/auth/types';
import {
  getEntitlementEbUserAccessLevel,
  getEntitlementHasDeveloperAccess
} from 'services/auth/selectors';
import { getOrganizationIsProdEnv } from 'services/organizations/selectors';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { matchRoutePath } from 'utils/matchRoutePath';

export const SUPER_USER_PATHS = [
  '/organizations',
  '/user-management',
  '/bulk-action-management',
  '/files',
  '/api',
  '/api/spec'
];

export function useNavLinkManager() {
  // Hooks
  const { companyCode } = useParams();

  // Hooks - selectors
  const accessLevel = useSelector(getEntitlementEbUserAccessLevel);
  const isProd = useSelector(getOrganizationIsProdEnv);
  const hasDevAccess = useSelector(getEntitlementHasDeveloperAccess);

  const hasAccessToOrgsAndFiles = [
    AccessLevel.FULL,
    AccessLevel.HR_SUPER,
    AccessLevel.HR,
    AccessLevel.ORG
  ].includes(accessLevel);

  const linkDataOrganization = [
    {
      title: 'Dashboard',
      path: `/${companyCode}/dashboard`,
      icon: 'home',
      isDisabled: !hasAccessToOrgsAndFiles || accessLevel === AccessLevel.HR
    },
    {
      title: 'File Management',
      path: `/${companyCode}/file-management`,
      icon: 'folder',
      isDisabled: !hasAccessToOrgsAndFiles || accessLevel === AccessLevel.HR
    },
    {
      title: 'People',
      path: `/${companyCode}/people`,
      icon: 'user_group',
      isDisabled: !hasAccessToOrgsAndFiles
    },
    {
      title: 'User Management',
      path: `/${companyCode}/user-management`,
      icon: 'adult',
      isDisabled: !hasAccessToOrgsAndFiles
    },
    {
      title: 'Bulk Action Management',
      path: `/${companyCode}/bulk-action-management`,
      icon: 'id',
      isDisabled: !isProd || accessLevel !== AccessLevel.FULL
    },
    {
      title: 'API Help',
      path: `/api`,
      icon: 'dns',
      isDisabled: !hasDevAccess
    }
  ];

  const linkDataHomePage = [
    {
      title: 'Organizations',
      path: '/organizations',
      icon: 'aura',
      isDisabled: !hasAccessToOrgsAndFiles
    },
    {
      title: 'User Management',
      path: '/user-management',
      icon: 'adult',
      isDisabled: !hasAccessToOrgsAndFiles
    },
    {
      title: 'Uploaded Files',
      path: `/files`,
      icon: 'folder',
      isDisabled: accessLevel !== AccessLevel.FULL
    },
    {
      title: 'API Help',
      path: `/api`,
      icon: 'dns',
      isDisabled: !hasDevAccess
    }
  ];

  return {
    links: matchRoutePath(SUPER_USER_PATHS, location.pathname)
      ? linkDataHomePage
      : linkDataOrganization
  };
}
