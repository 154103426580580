// Globals
import './styles.scss';
import React from 'react';

// Components
import { Heading } from 'components/Heading';
import { Modal } from 'components/Modal';
import { ModalBodyText } from 'components/Modal/BodyText';
import { ModalFileUploadInitial } from './Initial';
import { ModalFileUploadFailure } from './Failure';
import { ModalFileUploadSuccess } from './Success';
import { ModalFileUploadUploading } from './Uploading';

// Types
import {
  FILE_UPLOAD_INITITAL_STATE,
  FILE_UPLOAD_STEP,
  FileUploadState,
  ModalUploadFileProps
} from './types';

// Component
const ModalFileUpload: React.FC<ModalUploadFileProps> = ({
  title,
  header,
  footer,
  handleUploadFile,
  hide,
  isVisible,
  onSuccessClose
}) => {
  // Hooks - state
  const [uploadState, setUploadState] = React.useState<FileUploadState>(FILE_UPLOAD_INITITAL_STATE);
  const timerId = React.useRef<EbTimeout>();

  // Hooks - effects
  React.useEffect(() => {
    clearTimeout(timerId.current);
  }, []);

  // Handlers
  const handleUpload = async (file: FileList) => {
    try {
      setUploadState({ step: FILE_UPLOAD_STEP.UPLOADING });
      await handleUploadFile(file);
      setUploadState({ step: FILE_UPLOAD_STEP.SUCCESS });
    } catch (error) {
      setUploadState({ step: FILE_UPLOAD_STEP.FAILURE, error: String(error) });
    }
  };

  const handleBack = () => {
    if (uploadState.step !== FILE_UPLOAD_STEP.INITIAL) {
      setUploadState(FILE_UPLOAD_INITITAL_STATE);
    }
  };

  const handleOnClose = () => {
    timerId.current = setTimeout(() => {
      if (uploadState.step === FILE_UPLOAD_STEP.SUCCESS && onSuccessClose) onSuccessClose();

      setUploadState(FILE_UPLOAD_INITITAL_STATE);
    }, 1000);

    hide();
  };

  const renderChildren = () => {
    switch (uploadState.step) {
      case FILE_UPLOAD_STEP.INITIAL:
        return (
          <>
            {header}
            <ModalFileUploadInitial footer={footer} onUpload={handleUpload} />
          </>
        );
      case FILE_UPLOAD_STEP.UPLOADING:
        return (
          <>
            {header}
            <ModalFileUploadUploading />
          </>
        );
      case FILE_UPLOAD_STEP.SUCCESS:
        return <ModalFileUploadSuccess onBack={handleBack} onClose={handleOnClose} />;
      case FILE_UPLOAD_STEP.FAILURE:
        return <ModalFileUploadFailure error={uploadState.error ?? ''} onBack={handleBack} />;
    }
  };

  return (
    <Modal className="eb-modal_upload_file" hide={handleOnClose} isVisible={isVisible}>
      <ModalBodyText>
        {typeof title === 'string' ? (
          <Heading align="center" className="eb-modal_upload_file-header" size={16} type="h1">
            {title}
          </Heading>
        ) : (
          title
        )}

        <section className="eb-modal_upload_file-content">{renderChildren()}</section>
      </ModalBodyText>
    </Modal>
  );
};

export { ModalFileUpload };
