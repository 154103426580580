// Globals
import React from 'react';

// Components
import { Api } from 'pages/Api';
import { BulkActionManagement } from 'pages/BulkActionManagement';
import { Dashboard } from 'pages/Dashboard';
import { Error404 } from 'pages/Error404';
import { FileDetails } from './pages/FileDetails';
import { FileManagement } from 'pages/FileManagement';
import { Files } from 'pages/Files';
import { Home } from 'pages/Home';
import { MfaSetup } from 'pages/MfaSetup';
import { Organizations } from './pages/Organizations';
import { PasswordForgot } from 'pages/PasswordForgot';
import { PasswordResetSuccess } from 'pages/PasswordResetSuccess';
import { PasswordSet } from 'pages/PasswordSet';
import { People } from 'pages/People';
import { SignIn } from 'pages/SignIn';
import { UserManagement } from 'pages/UserManagement';

// Types
import { AccessLevel } from 'services/auth/types';
import { ApiSpec } from './pages/ApiSpec';
import { PeopleDetails } from 'pages/PeopleDetails';

export interface EbRoute {
  accessUserGroup?: AccessLevel[];
  auth?: boolean;
  component: React.ElementType;
  exact?: boolean;
  name?: string;
  path?: string;
}

const routes: EbRoute[] = [
  {
    auth: true,
    component: Home,
    path: '/'
  },
  {
    auth: true,
    component: Api,
    path: '/api',
    name: 'API Help'
  },
  {
    auth: true,
    component: ApiSpec,
    path: '/api/spec',
    name: 'Endpoints'
  },
  {
    auth: false,
    component: PasswordForgot,
    path: '/forgot-password'
  },
  {
    component: PasswordResetSuccess,
    path: '/reset-password-success'
  },
  {
    component: PasswordSet,
    path: '/reset-password'
  },
  {
    accessUserGroup: [AccessLevel.FULL],
    auth: true,
    component: Organizations,
    path: '/organizations'
  },
  {
    accessUserGroup: [AccessLevel.FULL, AccessLevel.HR_SUPER, AccessLevel.ORG],
    auth: true,
    exact: true,
    component: Dashboard,
    path: '/:companyCode/dashboard'
  },
  {
    accessUserGroup: [AccessLevel.FULL, AccessLevel.HR_SUPER, AccessLevel.ORG],
    auth: true,
    exact: true,
    component: FileManagement,
    path: '/:companyCode/file-management',
    name: 'File management'
  },
  {
    accessUserGroup: [AccessLevel.FULL],
    auth: true,
    exact: true,
    component: Files,
    path: '/files',
    name: 'Files'
  },
  {
    auth: true,
    exact: true,
    component: UserManagement,
    path: '/:companyCode/user-management',
    name: 'User Management'
  },
  {
    accessUserGroup: [AccessLevel.FULL, AccessLevel.ORG],
    auth: true,
    exact: true,
    component: UserManagement,
    path: '/user-management',
    name: 'User Management'
  },
  {
    accessUserGroup: [AccessLevel.FULL, AccessLevel.ORG],
    auth: true,
    exact: true,
    component: BulkActionManagement,
    path: '/:companyCode/bulk-action-management',
    name: 'Bulk Action Management'
  },
  {
    accessUserGroup: [AccessLevel.FULL, AccessLevel.ORG],
    auth: true,
    exact: true,
    component: FileDetails,
    path: '/:companyCode/bulk-action-management/:fileId',
    name: 'Review file'
  },
  {
    accessUserGroup: [AccessLevel.FULL, AccessLevel.ORG, AccessLevel.HR, AccessLevel.HR_SUPER],
    auth: true,
    exact: true,
    component: FileDetails,
    path: '/:companyCode/file-management/:fileId',
    name: 'Review Enrollment file'
  },
  {
    auth: true,
    exact: true,
    component: People,
    path: '/:companyCode/people',
    name: 'People'
  },
  {
    accessUserGroup: [AccessLevel.FULL, AccessLevel.ORG, AccessLevel.HR, AccessLevel.HR_SUPER],
    auth: true,
    exact: true,
    component: PeopleDetails,
    path: '/:companyCode/people/:userId',
    name: 'People Detail'
  },
  {
    auth: false,
    component: SignIn,
    path: '/sign-in'
  },
  {
    auth: false,
    component: MfaSetup,
    path: '/mfa-setup'
  },
  {
    exact: true,
    path: '/not-found',
    component: Error404
  },
  {
    path: '*',
    component: Error404
  }
];

export { routes };
