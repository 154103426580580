// Global
import React from 'react';
import './styles.scss';

// Components
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { Loading } from 'components/Loading';
import { ModalFileUploadBulkAction } from 'modals/ModalFileUploadBulkAction';
import { ModalFileUploadEnrollment } from 'modals/ModalFileUploadEnrollment';
import { Paragraph } from 'components/Paragraph';

// Assets
import illustration from 'assets/media/snap.svg';

export enum FileUploadType {
  ENROLLMENT = 'enrollment',
  BULK_ACTION = 'bulk'
}

interface NoFilesProps {
  heading?: React.ReactNode;
  description?: React.ReactNode;
  fileUploadType: FileUploadType;
}

const NoFiles: React.FC<NoFilesProps> = ({ heading, description, fileUploadType }) => {
  // State
  const [isUploadFileModalVisible, setIsUploadFileModalVisible] = React.useState(false);
  const [isModalSuccessClose, setIsModalSuccessClose] = React.useState(false);

  // Render
  const renderHeading = () => {
    if (heading) return heading;

    switch (fileUploadType) {
      case FileUploadType.ENROLLMENT:
        return 'No enrollments files uploaded yet';
      case FileUploadType.BULK_ACTION:
        return 'No files uploaded yet';
      default:
        return null;
    }
  };

  const renderDescription = () => {
    if (description) return description;

    switch (fileUploadType) {
      case FileUploadType.ENROLLMENT:
        return "Please upload company's first enrollment list";
      case FileUploadType.BULK_ACTION:
        return "Please upload company's first bulk action file";
      default:
        return null;
    }
  };

  const renderChildren = () => {
    if (isModalSuccessClose)
      return (
        <Loading>
          The file is still processing.
          <br /> Please reload the page or come back later.
        </Loading>
      );

    return (
      <>
        <img alt="No files illustration" className="eb-no_files-image" src={illustration} />
        <Heading align="center" className="eb-no_files-heading" size={26} type="h4">
          {renderHeading()}
        </Heading>
        <Paragraph align="center" className="eb-no_files-text">
          {renderDescription()}
        </Paragraph>
        <Button className="eb-no_files-button" onClick={() => setIsUploadFileModalVisible(true)}>
          Add file
        </Button>
      </>
    );
  };

  const renderModal = () => {
    switch (fileUploadType) {
      case FileUploadType.ENROLLMENT:
        return (
          <ModalFileUploadEnrollment
            hide={() => setIsUploadFileModalVisible(false)}
            isVisible={isUploadFileModalVisible}
            onSuccessClose={() => setIsModalSuccessClose(true)}
          />
        );
      case FileUploadType.BULK_ACTION:
        return (
          <ModalFileUploadBulkAction
            hide={() => setIsUploadFileModalVisible(false)}
            isVisible={isUploadFileModalVisible}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="eb-no_files">
      {renderChildren()}
      {renderModal()}
    </div>
  );
};

export { NoFiles };
