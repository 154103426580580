import './styles.scss';
import React from 'react';

// Components
import { Button } from 'components/Button';
import { Dot } from 'components/Dot';
import { ErrorServer } from 'components/ErrorServer';
import { Heading } from 'components/Heading';
import { LayoutPage } from 'components/LayoutPage';
import { Loading } from 'components/Loading';
import { FileUploadType, NoFiles } from 'components/NoFiles';
import { Paragraph } from 'components/Paragraph';
import { SummaryCard } from 'components/SummaryCard';
import { TagStatus } from 'components/TagStatus';

// Services
import { doGetCurrentCompanyPeople } from 'services/people/async';
import {
  doEndOrganizationOnboarding,
  doGetOrganizationDetails
} from 'services/organizations/async';
import {
  getEndpointPending,
  getEndpointsAnyFailure,
  getEndpointsAnyPending
} from 'services/api/selectors';
import { getEntitlementsIsAuraAdmin } from 'services/auth/selectors';
import {
  getOrganizationIsProdEnv,
  getOrganizationsSelectedOrganizationDetails,
  getOrganizationsSelectedOrganizationFiles,
  getOrganizationsSelectedOrganizationRecentFile
} from 'services/organizations/selectors';
import { getPeopleEligibleCount, getPeopleEnrolledCount } from 'services/people/selectors';

// Misc
import { EbDispatch, EbState } from 'store';
import { getFileLabel, getFileStatusDisplayName, getFileStatusTag } from 'utils/statuses';
import { format, isValid } from 'date-fns';
import { Status } from 'components/TagStatus/types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Assets
import unlock from 'assets/media/unlock.svg';

const DATE_FORMAT = 'MMM d, yyyy, hh:mm a';

function Dashboard() {
  // Hooks
  const dispatch: EbDispatch = useDispatch();
  const { companyCode } = useParams();

  // Hooks - selectors
  const isLoading = useSelector((state: EbState) =>
    getEndpointsAnyPending(state, [doGetCurrentCompanyPeople, doGetOrganizationDetails])
  );
  const isPending = useSelector((state: EbState) =>
    getEndpointPending(state, doEndOrganizationOnboarding)
  );
  const isError = useSelector((state: EbState) =>
    getEndpointsAnyFailure(state, [doGetCurrentCompanyPeople, doGetOrganizationDetails])
  );
  const { programs = [], org } = useSelector(getOrganizationsSelectedOrganizationDetails);
  const eligiblePeopleCount = useSelector(getPeopleEligibleCount);
  const enrolledPeopleCount = useSelector(getPeopleEnrolledCount);
  const files = useSelector(getOrganizationsSelectedOrganizationFiles);
  const { fileId, fileName, fileStatus, uploadedAt, failureCount, submittedBy } = useSelector(
    getOrganizationsSelectedOrganizationRecentFile
  );
  const isProd = useSelector(getOrganizationIsProdEnv);
  const isAuraAdmin = useSelector(getEntitlementsIsAuraAdmin);

  // Hooks - effects
  React.useEffect(() => {
    void dispatch(doGetCurrentCompanyPeople());
  }, [dispatch]);

  // Handler
  const handleEndOnboarding = async () => {
    await dispatch(doEndOrganizationOnboarding(org?.code));
  };

  // Render
  const renderHeading = () => {
    if (isLoading)
      return (
        <Heading className="eb-dashboard-heading" size={32} type="h3">
          Dashboard
        </Heading>
      );

    const programsCount = programs?.length ?? 0;
    const onboardingText = isAuraAdmin
      ? "This organization is in Onboarding. When they're ready, you can let them start enrollment."
      : 'You can upload as many test files as you need.';

    return (
      <>
        <Heading className="eb-dashboard-heading" size={32} type="h3">
          Dashboard
        </Heading>

        <div className="eb-dashboard-programs">
          {programs.map((item, index) => (
            <React.Fragment key={item.programTag}>
              <span className="eb-dashboard-programs-item">
                {item.enrollmentType?.toLowerCase()}
              </span>
              <Dot />
              <span className="eb-dashboard-programs-item">
                {item.billingSource?.toLowerCase()}
              </span>
              {index < programsCount - 1 && (
                <span className="eb-dashboard-programs-divider"> &amp; </span>
              )}
            </React.Fragment>
          ))}
        </div>
        {!isProd && (
          <div className="eb-dashboard-onboarding">
            <div className="eb-dashboard-onboarding-content">
              <Heading className="eb-dashboard-onboarding-heading" size={22} type="h6">
                Onboarding mode
              </Heading>
              <Paragraph size={16}>{onboardingText}</Paragraph>
              {isAuraAdmin && (
                <Button
                  className="eb-dashboard-onboarding-button"
                  isLoading={isPending}
                  onClick={handleEndOnboarding}
                >
                  Start Enrollment
                </Button>
              )}
            </div>
            <img alt="unlock" className="eb-dashboard-onboarding-image" src={unlock} />
          </div>
        )}
      </>
    );
  };

  const renderSummaries = () => {
    if (!eligiblePeopleCount) return null;

    return (
      <>
        <Heading className="eb-dashboard-subheading" size={22} type="h5">
          Overview
        </Heading>
        <div className="eb-dashboard-summaries">
          <SummaryCard
            icon="user_group"
            path={`/${companyCode}/people`}
            title="Eligible people"
            total={eligiblePeopleCount}
          />
          <SummaryCard icon="logout" title="Enrolled" total={enrolledPeopleCount} />
        </div>
      </>
    );
  };

  const renderRecentFile = () => {
    if (!fileName) return null;

    const tagStatus = getFileStatusTag(fileStatus, failureCount);
    const fileStatusName = getFileStatusDisplayName(fileStatus, 'File');
    const uploadedDate = isValid(new Date(uploadedAt)) && format(new Date(uploadedAt), DATE_FORMAT);
    const uploadedBy = submittedBy
      ? ` by ${submittedBy.username ?? submittedBy.username ?? ''}`
      : '';

    return (
      <>
        <Heading className="eb-dashboard-subheading" size={22} type="h5">
          Recent uploads
        </Heading>
        <div className="eb-dashboard-file">
          <div className="eb-dashboard-file-details">
            <div className="eb-dashboard-file-details-main">
              <Heading className="eb-dashboard-file-details-main-heading" size={22} type="h5">
                {fileName}
              </Heading>
              <TagStatus className="eb-dashboard-file-details-main-status" status={tagStatus}>
                {fileStatusName}
              </TagStatus>
            </div>
            {failureCount > 0 && (
              <div className="eb-dashboard-file-details-errors">
                {failureCount} record{failureCount > 1 ? `s` : ''} rejected
              </div>
            )}
            <div className="eb-dashboard-file-details-secondary">
              <Paragraph size={14}>{getFileLabel(fileName)}</Paragraph>
              {uploadedDate && (
                <>
                  <Dot color="gray" />
                  <Paragraph size={14}>
                    {uploadedDate}
                    {uploadedBy}
                  </Paragraph>
                </>
              )}
            </div>
          </div>
          <Button
            goto={`/${companyCode}/file-management/${fileId}`}
            isDisabled={tagStatus === Status.PENDING}
          >
            View
          </Button>
        </div>
      </>
    );
  };

  const renderChildren = () => {
    if (isLoading) return <Loading isCentered>Loading...</Loading>;
    if (isError) return <ErrorServer />;
    if (!files.length) return <NoFiles fileUploadType={FileUploadType.ENROLLMENT} />;

    return (
      <>
        {renderSummaries()}
        {renderRecentFile()}
      </>
    );
  };

  return (
    <LayoutPage className="eb-dashboard" heading={renderHeading()}>
      {renderChildren()}
    </LayoutPage>
  );
}

export { Dashboard };
