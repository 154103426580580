// Global
import './styles.scss';
import React from 'react';

// Components
import { LayoutPage } from 'components/LayoutPage';
import { Loading } from 'components/Loading';
import { ErrorServer } from 'components/ErrorServer';
import { Paragraph } from 'components/Paragraph';
import { Icon } from 'components/Icon';
import { Heading } from 'components/Heading';
import { Anchor } from 'components/Anchor';
import { Button } from 'components/Button';
import { ModalMemberDetails } from 'modals/ModalMemberDetails';

// Services
import { clearMemberDetails } from 'services/people/reducers';
import { getMemberDetails } from 'services/people/selectors';
import { getEndpointError, getEndpointsAnyPending } from 'services/api/selectors';
import { doBulkActionMembers, doGetMemberDetails } from 'services/people/async';

// Misc
import { EbDispatch, EbState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { formatBirthDate, formatDate } from 'utils/formatDate';
import { isFuture, isValid } from 'date-fns';
import { useParams } from 'react-router-dom';
import config from 'config';
import { MemberAction } from 'services/people/types';
import { ModalConfirmation } from 'modals/ModalConfirmation';
import { ModalConfirmationProps } from 'modals/ModalConfirmation/types';
import { setToastError, setToastSuccess } from 'services/toast/reducers';
import { getMemberDisplayName } from 'utils/statuses';

export interface RowProps {
  label: string;
  value: string | undefined;
}

const MemberRow: React.FC<RowProps> = ({ label, value }) => {
  return (
    <div className="eb-people_details-content-details-table">
      <Paragraph>{label}</Paragraph>
      <Paragraph>{value}</Paragraph>
    </div>
  );
};

function PeopleDetails() {
  // Hooks
  const dispatch: EbDispatch = useDispatch();
  const params = useParams();

  // Hooks - Selectors
  const isPending = useSelector((state: EbState) =>
    getEndpointsAnyPending(state, [doGetMemberDetails, doBulkActionMembers])
  );
  const error = useSelector((state: EbState) => getEndpointError(state, doGetMemberDetails));
  const {
    companyUserId,
    birthDate,
    deptId,
    emailAddress,
    employeeId,
    name,
    planTitle,
    status,
    updatedAt,
    userId,
    effectiveDate,
    scheduledChangeType
  } = useSelector(getMemberDetails);

  // Hooks - state
  const [isMemberDetailModalVisible, setIsMemberDetailModalVisible] = React.useState(false);
  const [isConfirmTermModalVisible, setIsConfirmTermModalVisible] = React.useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = React.useState<
    Partial<ModalConfirmationProps>
  >({});
  const timerId = React.useRef<EbTimeout>();

  // Handlers
  const getPeopleDetails = React.useCallback(async () => {
    params?.userId && (await dispatch(doGetMemberDetails(params?.userId)));
  }, [dispatch, params]);

  const handleHideMemberDetailsModal = React.useCallback(
    (reloadNeeded: boolean) => {
      setIsMemberDetailModalVisible(false);
      timerId.current = setTimeout(() => {
        if (reloadNeeded) getPeopleDetails();
      }, 1000);
    },
    [getPeopleDetails]
  );

  const userAction = React.useCallback(
    async (action: MemberAction) => {
      try {
        setIsConfirmTermModalVisible(false);
        await dispatch(doBulkActionMembers(action, [userId]));
        dispatch(setToastSuccess('Successfully updated the member.'));
        dispatch(clearMemberDetails());
        getPeopleDetails();
      } catch (error: any) {
        dispatch(setToastError('Error updating user. Please try again.'));
        handleHideMemberDetailsModal(false);
      }
    },
    [dispatch, userId, handleHideMemberDetailsModal, getPeopleDetails]
  );

  const handleUserAction = React.useCallback(
    (action: MemberAction) => {
      const termMemberName =
        !name?.first && !name?.last ? 'they' : `${name?.first ?? ''} ${name.last ?? ''}`;

      setConfirmationModalProps({
        primaryButtonText: action === 'term' ? 'Term User' : 'Port User',
        text:
          action === 'term'
            ? `Once you term them, ${termMemberName} won't be covered under your plan benefits.`
            : `Once you port them, ${termMemberName} will be converted to a free trial subscription.`,
        onPrimaryButtonClick: () => userAction(action),
        onSecondaryButtonClick: () => {
          setIsConfirmTermModalVisible(false);
          setIsMemberDetailModalVisible(true);
        }
      });
      setIsConfirmTermModalVisible(true);
    },
    [name, userAction]
  );

  // Hooks - effects
  React.useEffect(() => {
    getPeopleDetails();
  }, [getPeopleDetails]);

  React.useEffect(
    () => () => {
      clearTimeout(timerId.current);
    },
    []
  );

  // Vars
  const formatSubscriptionDate = formatDate(updatedAt);
  const isScheduled = effectiveDate && isValid(effectiveDate) && isFuture(new Date(effectiveDate));

  // Render
  const renderHeading = () =>
    error ? null : (
      <div className="eb-file_management-heading">
        <Heading size={32} type="h1">
          {name.first} {name.middle} {name.last}
        </Heading>
        <Paragraph>Last Updated {formatSubscriptionDate}</Paragraph>
      </div>
    );

  const renderChildren = () => {
    switch (true) {
      case isPending:
        return <Loading />;
      case Boolean(error):
        return (
          <ErrorServer
            title={
              error === 'default.not-found' ? 'Cannot find a record for this user ID' : undefined
            }
          />
        );
      default:
        return (
          <>
            <div className="eb-people_details-activity">
              <Heading className="eb-people_details-heading" size={26} type="h1">
                Activity
              </Heading>
              <Anchor
                className="eb-people_details-sub"
                href={`${config.ap_api_admin_origin}/user/${userId}/subscription`}
                intent="secondary"
                isNewTab
              >
                <Icon name="link" />
                Subscription Details
              </Anchor>
            </div>
            <div className="eb-people_details-content">
              <Heading className="eb-people_details-heading" size={26} type="h1">
                Profile
              </Heading>
              <div className="eb-people_details-content-details">
                <MemberRow label="First Name" value={name.first} />
                <MemberRow label="Middle Name" value={name?.middle} />
                <MemberRow label="Last Name" value={name.last} />
                <MemberRow label="Birth Date" value={formatBirthDate(birthDate)} />
                <MemberRow label="Company User ID" value={companyUserId} />
                <MemberRow label="Department ID" value={deptId} />
                <MemberRow label="Email Address" value={emailAddress} />
                <MemberRow label="Employee ID" value={employeeId} />
                <MemberRow label="Plan" value={planTitle} />
                <MemberRow
                  label={isScheduled ? 'Enrollment Status' : 'Subscription Status'}
                  value={getMemberDisplayName(status, { effectiveDate, scheduledChangeType })}
                />
                <MemberRow label="Aura User ID" value={userId} />
              </div>
              <Button
                className="eb-people_details-edit-button"
                onClick={() => setIsMemberDetailModalVisible(true)}
              >
                Edit profile
              </Button>
            </div>
          </>
        );
    }
  };

  return (
    <LayoutPage className="eb-people_details" hasBreadcrumbs heading={renderHeading()}>
      {renderChildren()}

      <ModalMemberDetails
        hide={handleHideMemberDetailsModal}
        isVisible={isMemberDetailModalVisible}
        onTermOrPortClick={(value) => {
          setIsMemberDetailModalVisible(false);
          handleUserAction(value);
        }}
      />
      <ModalConfirmation
        isVisible={isConfirmTermModalVisible}
        onPrimaryButtonClick={confirmationModalProps.onPrimaryButtonClick ?? (() => {})}
        onSecondaryButtonClick={() => {
          setIsConfirmTermModalVisible(false);
        }}
        secondaryButtonText="Cancel"
        {...confirmationModalProps}
      />
    </LayoutPage>
  );
}

export { PeopleDetails };
