import { createSelector } from 'reselect';
import { EbState } from 'store';
import { FilesState, FileListItem, FilesParamState } from './types';

export const initialFilesListParams: FilesParamState = {
  pageSize: 100,
  page: 0,
  totalPages: 0,
  total: 0,
  totalWithoutFilters: 0
};

export const initialState: FilesState = {
  allFiles: { list: [], params: initialFilesListParams }
};

export const getFiles = createSelector(
  (state: EbState) => state?.files,
  (files): FilesState => files
);

export const getAllFilesState = createSelector(getFiles, (filesState) => filesState?.allFiles);

export const getFilesList = createSelector(
  getAllFilesState,
  (filesState): FileListItem[] => filesState?.list || []
);

export const getFilesParams = createSelector(
  getAllFilesState,
  (filesState): FilesParamState => filesState?.params ?? initialFilesListParams
);

export const getFilesParamsPageSize = createSelector(
  getFilesParams,
  (params): number => params.pageSize
);

export const getFilesParamsPage = createSelector(getFilesParams, (params): number => params.page);

export const getFilesParamsTotal = createSelector(
  getFilesParams,
  (params): number => params.total || 0
);

export const getFilesParamsTotalWithoutFilters = createSelector(
  getFilesParams,
  (params): number => params.totalWithoutFilters || 0
);

export const getFilesParamsTotalPages = createSelector(
  getFilesParams,
  (params): number => params.totalPages
);
