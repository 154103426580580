// Global
import './styles.scss';
import React from 'react';

// Components
import { Heading } from 'components/Heading';
import { FilesListItem } from './ListItem';
import { Filter } from 'components/Filter';
import { LayoutPage } from 'components/LayoutPage';
import { TableList } from 'components/Table/List';
import { Toast } from 'components/Toast';

// Services
import { doListFiles } from 'services/files/async';
import {
  getFilesList,
  getFilesParams,
  getFilesParamsPage,
  getFilesParamsPageSize,
  getFilesParamsTotal,
  getFilesParamsTotalPages
} from 'services/files/selectors';
import { getEntitlementsIsAuraAdmin } from 'services/auth/selectors';
import {
  resetFilesFilters,
  setFilesFilters,
  setFilesPage,
  setFilesPageSize
} from 'services/files/reducers';

// Misc
import { EbDispatch } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FILTER_OPTIONS } from './data';
import { useFilter } from 'hooks/useFilterToggle';
import { Search } from '../../components/Search';
import { useSearchButton } from '../../hooks/useSearchButton';
import { Paragraph } from '../../components/Paragraph';
import { useEffectDidUpdate } from '../../hooks/useEffectDidUpdate';
import { FileUploadType, NoFiles } from '../../components/NoFiles';

// Const
const searchSuggestions = (
  <>
    <Paragraph>Suggestions:</Paragraph>
    <Paragraph>Make sure that company code is spelled correctly.</Paragraph>
    <Paragraph>Try a different company code.</Paragraph>
  </>
);

export const Files = () => {
  // Hooks
  const dispatch: EbDispatch = useDispatch();
  const navigate = useNavigate();

  // Hooks - selectors
  const userHasAllFilesAccess = useSelector(getEntitlementsIsAuraAdmin);
  const files = useSelector(getFilesList);
  const filterParams = useSelector(getFilesParams)
  const totalFilesSearch = useSelector(getFilesParamsTotal);

  // Hooks - state
  const [filtersCount, setFiltersCount] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');

  // Hooks - custom
  const { searchButton, isSearchInputActive } = useSearchButton();
  const { filterButton, isFilterOpen, toggleFilter } = useFilter(filtersCount);

  // Handlers
  const onSubmit = async (text: string) => {
    const search = text.toLowerCase();
    const {statusColor, uploadedAt} = filterParams;
    setSearchText(search);
    void dispatch(setFilesFilters({status: statusColor, uploadedAt, companyCode: search}));
    await dispatch(doListFiles());
  };

  const onCancel = () => {
    setSearchText('');
    void dispatch(setFilesFilters({ companyCode: '' }));
    void dispatch(doListFiles());
  };

  // Hooks - effects
  React.useEffect(() => {
    if (!userHasAllFilesAccess) navigate('/');
  }, [userHasAllFilesAccess, navigate]);

  useEffectDidUpdate(() => {
    if (!isSearchInputActive) {
      const {statusColor, uploadedAt} = filterParams;
      setSearchText('');
      void dispatch(setFilesFilters({status: statusColor, uploadedAt, companyCode: ''}));
      void dispatch(doListFiles());
    }
  }, [isSearchInputActive, dispatch]);

  const handleFilter = (filters: Record<string, string>) => {
    setFiltersCount(Object.keys(filters).length);
    dispatch(resetFilesFilters());
    dispatch(setFilesFilters({ ...filters, companyCode: searchText }));
    void dispatch(doListFiles());
    toggleFilter();
  };

  const renderChildren = () => {
    return (
      <TableList
        empty={<NoFiles fileUploadType={FileUploadType.ENROLLMENT} />}
        loadingText="Loading..."
        requests={[doListFiles]}
        selectorItemsTotal={getFilesParamsTotal}
        selectorPage={getFilesParamsPage}
        selectorPageSize={getFilesParamsPageSize}
        selectorPagesTotal={getFilesParamsTotalPages}
        setPage={(page) => dispatch(setFilesPage(page))}
        setPageSize={(pageSize) => dispatch(setFilesPageSize(pageSize))}
      >
        {files.map((file) => (
          <FilesListItem {...file} icon="file" key={file.id} />
        ))}
      </TableList>
    );
  };

  // Render
  return (
    <LayoutPage
      className="eb-files"
      heading={
        <div className="eb-files-heading">
          <Heading size={32} type="h1">
            Uploaded files
          </Heading>
          <div className="eb-files-heading-filter-buttons">
            {searchButton}
            {filterButton}
          </div>
        </div>
      }
    >
      <Toast />
      <Search
        isVisible={isSearchInputActive}
        onCancel={onCancel}
        onSubmit={onSubmit}
        placeHolder="Search by company code"
        request={doListFiles}
        suggestions={searchSuggestions}
        totalResultsFound={totalFilesSearch}
      />
      <Filter
        isVisible={isFilterOpen}
        onApply={handleFilter}
        onCancel={toggleFilter}
        options={FILTER_OPTIONS}
        request={doListFiles}
      />
      {renderChildren()}
    </LayoutPage>
  );
};
