import React from 'react';
import './styles.scss';

// Components
import { Badge } from 'components/Badge';
import { Button } from 'components/Button';
import { Dot } from 'components/Dot';
import { FileListItem } from 'components/FileListItem';
import { Heading } from 'components/Heading';
import { LayoutPage } from 'components/LayoutPage';
import { Loading } from 'components/Loading';
import { FileUploadType, NoFiles } from 'components/NoFiles';
import { ModalFileUploadBulkAction } from 'modals/ModalFileUploadBulkAction';
import { TableList } from 'components/Table/List';
import { Toast } from 'components/Toast';

// Services
import { doListFiles } from 'services/files/async';
import {
  getFilesList,
  getFilesParamsPage,
  getFilesParamsPageSize,
  getFilesParamsTotal,
  getFilesParamsTotalPages,
  getFilesParamsTotalWithoutFilters
} from 'services/files/selectors';
import { setFilesPage, setFilesPageSize } from 'services/files/reducers';
import { getEndpointPending } from 'services/api/selectors';
import { getOrganizationsSelected } from 'services/organizations/selectors';

// Misc
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import { EbDispatch, EbState } from 'store';
import { FileListItem as IFileListItem, FileType } from 'services/files/types';
import { getFileLabel } from 'utils/statuses';

// Const
const DATE_FORMAT = 'MMM d, yyyy, hh:mm a';
const FILE_TYPE_LABELS: Record<string, string> = {
  [FileType.EXTERNAL_ID_UPDATE]: 'CUID',
  [FileType.PROGRAM_CHANGE]: 'Program'
};

const BulkActionManagement: React.FC = () => {
  // Hooks
  const dispatch: EbDispatch = useDispatch();

  // Hooks - selectors
  const companyCode = useSelector(getOrganizationsSelected);
  const files = useSelector(getFilesList);
  const filesTotalWithoutFilter = useSelector(getFilesParamsTotalWithoutFilters);
  const isLoading = useSelector((state: EbState) => getEndpointPending(state, doListFiles));

  // Hooks - state
  const [isUploadFileModalVisible, setIsUploadFileModalVisible] = React.useState(false);

  // Render
  const renderHeading = () => (
    <div className="eb-bulk_action_management-heading">
      <Heading size={32} type="h1">
        Bulk Action Management
      </Heading>
    </div>
  );

  const renderFile = (file: IFileListItem) => {
    const uploadedBy = file.submittedBy
      ? ` by ${file.submittedBy.username ?? file.submittedBy.username ?? ''}`
      : '';
    const uploadedDate = format(new Date(file.uploadedAt), DATE_FORMAT);
    const successCount = file?.successCount ?? 0;
    const failureCount = file?.failureCount ?? 0;
    const noActionCount = file?.noActionCount ?? 0;

    const totalCount = successCount + failureCount + noActionCount ?? 0;
    const footer = (
      <div className="eb-bulk_action_management-file-info">
        <Badge
          className="eb-bulk_action_management-file-info-type"
          status={file.type === FileType.EXTERNAL_ID_UPDATE ? 'success' : ''}
        >
          {FILE_TYPE_LABELS[file.type]}
        </Badge>
        {getFileLabel(file.name)}
        {uploadedDate && (
          <>
            <Dot color="gray" />
            <div>
              {uploadedDate}
              {uploadedBy}
            </div>
          </>
        )}
        <div className="file-id">File ID: <strong>{file.id}</strong></div>
        <div className="eb-bulk_action_management-summary-counts">
          <div className="eb-bulk_action_management-summary-counts-item">
            <span>Total</span>
            <span>{totalCount}</span>
          </div>
          <div className="eb-bulk_action_management-summary-counts-item">
            <span>Accepted</span>
            <span>{successCount}</span>
          </div>
          <div className="eb-bulk_action_management-summary-counts-item">
            <span>Rejected</span>
            <span>{failureCount}</span>
          </div>
          <div className="eb-bulk_action_management-summary-counts-item">
            <span>No action</span>
            <span>{noActionCount}</span>
          </div>
        </div>
      </div>
    );

    return (
      <FileListItem
        author={uploadedBy}
        detailsUrl={`/${companyCode}/bulk-action-management/${file.id}`}
        errorsCount={file.failureCount}
        fileName={file.name}
        footer={footer}
        icon="file"
        key={file.id}
        status={file.status}
        updated={uploadedDate}
      />
    );
  };

  return (
    <LayoutPage className="eb-bulk_action_management" heading={renderHeading()}>
      <Toast />

      {filesTotalWithoutFilter > 0 && !isLoading && (
        <Button
          className="eb-bulk_action_management-upload-button"
          icon="document_add"
          intent="secondary"
          onClick={() => setIsUploadFileModalVisible(true)}
        >
          Upload a bulk action file
        </Button>
      )}

      <TableList
        empty={
          isLoading ? (
            <Loading isCentered>Loading...</Loading>
          ) : (
            <NoFiles fileUploadType={FileUploadType.BULK_ACTION} />
          )
        }
        requests={[() => doListFiles([FileType.EXTERNAL_ID_UPDATE, FileType.PROGRAM_CHANGE])]}
        selectorItemsTotal={getFilesParamsTotal}
        selectorItemsTotalWithoutFilters={getFilesParamsTotalWithoutFilters}
        selectorPage={getFilesParamsPage}
        selectorPageSize={getFilesParamsPageSize}
        selectorPagesTotal={getFilesParamsTotalPages}
        setPage={(page) => dispatch(setFilesPage(page))}
        setPageSize={(pageSize) => dispatch(setFilesPageSize(pageSize))}
        title="File History"
      >
        {files.map((file) => renderFile(file))}
      </TableList>

      <ModalFileUploadBulkAction
        hide={() => setIsUploadFileModalVisible(false)}
        isVisible={isUploadFileModalVisible}
      />
    </LayoutPage>
  );
};

export { BulkActionManagement };
